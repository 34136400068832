import React, { useState } from "react";
import "./App.css";
import Amplify, { API, Auth } from "aws-amplify";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { withAuthenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import "bootstrap/dist/css/bootstrap.min.css";

Amplify.configure({
	Auth: {
		region: "us-east-1",
		userPoolId: "us-east-1_dd0jyW7kZ",
		userPoolWebClientId: "5mghm1osfiefbc2jg8fene9n8a",
	},
	API: {
		endpoints: [
			{
				name: "jeevesauth",
				endpoint: "https://authapi.jeeves2021.com/release",
				region: "us-east-1",
			},
		],
	},
});

function App({ signOut, user }) {
	const [apiData, setApiData] = useState('');
	const [selectedProcess, setProcess] = useState('');
	const [enteredValue, setValue] = useState('');
	
	const processChangeHandler = (event) => {
		setProcess(event.target.value);
	};
	
	const valueChangeHandler = (event) => {
		setValue(event.target.value);
	};
	
	async function callApi() {
		const user = await Auth.currentAuthenticatedUser();
		const token = user.signInUserSession.idToken.jwtToken;
		let requestInfo;
		let responseJson;
		if (selectedProcess === "signer_hashes") {
			responseJson = {
				'query' : {
					'AQS_message0': {
						'process': selectedProcess,
						'value': enteredValue,
					}
				},
				'reponse': {}
			};
				
			requestInfo = {
				headers: {
					'Authorization': token,
					'Content-Type': 'application/json',
					},
				body: {
					'AQS_message0': {
						'process': selectedProcess,
						'value': enteredValue,
					}
				}
			}
		}
		else {
			responseJson = {
				'query' : {
					'WT_message0': {
						'process': selectedProcess,
						'value': enteredValue,
						}
				},
				'response': {}	
			};
			requestInfo = {
				headers: {
					'Authorization': token,
					'Content-Type': 'application/json',
					},
				body: {
					'WT_message0': {
						'process': selectedProcess,
						'value': enteredValue,
					}
				}
			}
		}
		API.post('jeevesauth', '/query', requestInfo).then(result => {
			if ( ('requestStatusCode' in result) && (result.requestStatusCode === 200) ) {
				if ('requestUID' in result) {
					responseJson['response']['requestUID'] = result.requestUID;
					if ( ('WT_response0' in result) && ('Artifact0' in result.WT_response0) ) {
						responseJson['response']['WT_response0'] = {};
						if (Object.keys(result.WT_response0.Artifact0).length > 4) {
							responseJson['response']['WT_response0']['results'] = JSON.parse(JSON.stringify(result.WT_response0.Artifact0));
							delete responseJson['response']['WT_response0']['results']['DTG'];
							delete responseJson['response']['WT_response0']['results']['requestStatusCode'];
							delete responseJson['response']['WT_response0']['results']['requestStatus'];
							console.log('Results Found');
						}
						else {
							responseJson['response']['WT_response0']['results'] = 'None';
							console.log('No Results Found');
						}				
					}
					else if ( ('AQS_response0' in result) && ('Artifact0' in result.AQS_response0) ) {
						responseJson['response']['AQS_response0'] = {};
						if (Object.keys(result.AQS_response0.Artifact0).length > 4) {
							responseJson['response']['AQS_response0']['results'] = JSON.parse(JSON.stringify(result.AQS_response0.Artifact0));
							delete responseJson['response']['AQS_response0']['results']['DTG'];
							delete responseJson['response']['AQS_response0']['results']['requestStatusCode'];
							delete responseJson['response']['AQS_response0']['results']['requestStatus'];
							console.log('Results Found');
						}
						else {
							responseJson['response']['AQS_response0']['results'] = 'None';
							console.log('No Results Found');
						}
				
			}
					else{
						responseJson['response']['WT_response0'] = {};
						responseJson['response']['WT_response0']['results'] = 'ERROR: no Artifacts returned in results.';
						console.log('No Artifacts returned.');
					}
				}
				else {
					responseJson['response']['WT_response0'] = {};
					responseJson['response']['WT_response0']['results'] = 'ERROR: requestUID not returned from server.';
					console.log('No requestUID Found');
				}
			}
			else if ('requestStatusCode' in result){
				responseJson['response']['WT_response0'] = {};
				responseJson['response']['WT_response0']['results'] = result.requestStatus;
				console.log('Error Found');
			}
			setApiData(responseJson);
		}).catch(err => {
			console.log(err);
		});
	};
		
	const submitHandler = (event) => {
		event.preventDefault();
		
		setApiData('Query Running');
		
		callApi();
		
	};
	
	const saveDataHandler = (event) => {
		event.preventDefault();
		
		const fileData = JSON.stringify(apiData, null, 2);
		const blob = new Blob([fileData], {type: "text/plain"});
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.download = `result.json`;
		link.href = url;
		link.click();
	};
	
	
	return (
		<div>
			<Navbar bg="dark" variant="dark">
				<Container className="wrapper">
					<Navbar.Brand href="#home">Jeeves</Navbar.Brand>
					<Navbar.Toggle />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="me-auto">
							<Navbar.Text>Signed in as: <a href="#login">{user.username}</a></Navbar.Text>
						</Nav>
						<Nav>
							<Button onClick={signOut}>Sign out</Button>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<div>
				<hr />
				<p className="text-center">This page provides a prototype capability to query Broadcom's Watch Tower Threat Intelligence API.</p>
				<p className="text-center">Only IOCs observed within Broadcom's customer base will return results.</p>
				<hr />				

				<Container>
					<Form horizontal onSubmit={submitHandler} role="form">
						<Row>
							<Col>
								<Form.Group controlId="form.Process">
									<Form.Control required as="select" type="select" value={selectedProcess} onChange={processChangeHandler}>
										<option value="">Select Process</option>
										<option value="file_protection">File Protection</option>
										<option value="file_related">File Related</option>
										<option value="file_insight">File Insight</option>
										<option value="file_processchain">File Process Chain</option>
										<option value="network_protection">Network Protection</option>
										<option value="network_related">Network Related</option>
										<option value="network_insight">Network Insight</option>
									</Form.Control>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="form.value">
									<Form.Control type="text" placeholder="Enter Value" value={enteredValue} required onChange={valueChangeHandler} />
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="form.submit">
									<Button type='submit'>Submit</Button>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</Container>
			</div>
			
			<div>
				<hr />
				<Container>
					<Form>
						<Form.Group controlId="form.results">
							<Form.Control readOnly as="textarea" rows="10" value={ JSON.stringify(apiData, null, 2) } />
						</Form.Group>
						<Form.Group controlId="form.save">
							<Button onClick={saveDataHandler}>Save Data</Button>
						</Form.Group>
					</Form>
				</Container>
			</div>
		</div>
	);
}

export default withAuthenticator(App);